
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
@import './swiper-pagination.scss';
@import './swiper-navigation.scss';
@import 'eco-design-micro/css/root.scss';
@import 'lowcode/plugins2/text-textarea/content/index.scss';
@import 'lowcode/plugins2/text-richtext/content/index.scss';
@import 'lowcode/plugins2/resource-img-video/content/index.scss';
@import 'lowcode/plugins/space/Space/content/index.scss';

:root {
    --content-width: 1200px;
    --content-width-padding: 1248px;
    --max-width-pc: 1492px;
    --padding-pc: 0 146px;
    --padding-pad: 0 134px;
    --padding-h5: 0 24px;
    --text-width-max: 996px;
    --text-width-pc: 734px;
    --ef-color-white: #fff;
    --ef-color-black: #000;
    --ef-color-blue: #3F68E0;
    --ef-color-gray: #949494;

    --eco-design-font-EB: 'EN-HelveticNeue-woff2-EB', 'JP-NOTOSANS-woff2-ExtraBold', 'CN-SourceHanSansCN-woff2-ExtraBold', 'Arial', sans-serif;
    --eco-design-font-B: 'EN-HelveticNeue-woff2-B', 'JP-NOTOSANS-woff2-Bold', 'CN-SourceHanSansCN-woff2-Bold', 'Arial', sans-serif;
    --eco-design-font-EM: 'EN-HelveticNeue-woff2-EM', 'JP-NOTOSANS-woff2-SemiBold', 'CN-SourceHanSansCN-woff2-SemiBold', 'Arial', sans-serif;
    --eco-design-font-M: 'EN-HelveticNeue-woff2-M', 'JP-NOTOSANS-woff2-Medium', 'CN-SourceHanSansCN-woff2-Medium', 'Arial', sans-serif;
    --eco-design-font-EL: 'EN-HelveticNeue-woff2-EL', 'JP-NOTOSANS-woff2-Regular', 'CN-SourceHanSansCN-woff2-Regular', 'Arial', sans-serif;
    --eco-design-font-L: 'EN-HelveticNeue-woff2-L', 'JP-NOTOSANS-woff2-Light', 'CN-SourceHanSansCN-woff2-Light', 'Arial', sans-serif;
}

body {
    background-color: #fff;
}

.editor-white {
    background-color: #fff;
}
.editor-black {
    background-color: #000;
}
.editor-gray {
    background-color: #F5F5F5;
}

body, table, p, div, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    color: inherit;
}

a:hover {
    color: inherit;
}

// 全局弹窗
.glo-editor-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 120;
    display: none;
    background-color: rgba(0,0,0,.5);
}
.glo-editor-modal.glo-editor-modal-show {
    display: block;
}
.glo-editor-modal .glo-editor-modal-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.glo-editor-modal .glo-editor-modal-content {
    display: block;
    background-color: #fff;
    margin: 0 auto;
    width: 500px;
}
.glo-editor-modal .glo-editor-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 24px;
    margin-bottom: 8px;
}
.glo-editor-modal .glo-editor-modal-close {
    cursor: pointer;
}
.glo-editor-modal .glo-editor-modal-body {
    padding: 0 24px;
}
.glo-editor-modal .glo-editor-modal-footer {
    margin-top: 12px;
    padding: 0 24px;
}

// 多行文案动画--css
.editor-ani-trigger {
    .editor-ani-title-1, .editor-ani-title-2, .editor-ani-title-3, .editor-ani-title-4, .editor-ani-title-5 {
        opacity: 0;
        transform: translateY(120px);
        transition: opacity .8s linear, transform .4s cubic-bezier(.26,.67,.48,.91);
    }
    &.isEnter {
        .editor-ani-title-1, .editor-ani-title-2, .editor-ani-title-3, .editor-ani-title-4, .editor-ani-title-5 {
            opacity: 1;
            transform: translateY(0);
        }
        .editor-ani-title-1 {
            transition: opacity .8s linear 0s, transform .4s cubic-bezier(.26,.67,.48,.91) 0s;
        }
        .editor-ani-title-2 {
            transition: opacity .8s linear 0.1s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.1s;
        }
        .editor-ani-title-3 {
            transition: opacity .8s linear 0.2s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.2s;
        }
        .editor-ani-title-4 {
            transition: opacity .8s linear 0.3s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.3s;
        }
        .editor-ani-title-5 {
            transition: opacity .8s linear 0.4s, transform .4s cubic-bezier(.26,.67,.48,.91) 0.4s;
        }
    }
}

// 黑色滚动条
.scrollbar-editor-black::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    overflow: visible;
    background-color: transparent;
    box-shadow: inset 1px 1px 0 transparent;
}
.scrollbar-editor-black::-webkit-scrollbar-track {
    background: transparent;
}
.scrollbar-editor-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #626262;
    background-clip: padding-box;
}

// 白色滚动条
.scrollbar-editor-white::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    overflow: visible;
    background-color: transparent;
    box-shadow: inset 1px 1px 0 transparent;
}
.scrollbar-editor-white::-webkit-scrollbar-track {
    background: transparent;
}
.scrollbar-editor-white::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #626262;
    background-clip: padding-box;
}

// shopify--仅会员标签
.shopify-member-only {
    display: inline-flex;
    height: 24px;
    color: #fff;
    border-radius: 2px;
    overflow: hidden;
    background: linear-gradient(85deg,#ec8e1b .25%,#fba11a 70.13%,#fed03c 129.07%);
    clip-path: polygon(7% 0,100% 0,100% 100%,7% 100%,0 50%);
    padding: 1px;
    margin-left: 14px;
    vertical-align: middle;
    font-size: 12px;
}
.shopify-member-only > span {
    background: #f5f5f5;
    clip-path: polygon(7% 0,100% 0,100% 100%,7% 100%,0 50%);
    padding: 0 21px;
    color: #fba11a;
    line-height: 22px;
}
// shopify--购买按钮
.shopify-buy-now {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0;
    max-width: 180px;
    border-radius: 33px;
    background: linear-gradient(261deg, #EC8E1B 17.46%, #FBA11A 64.01%, #FED03C 127.35%);
    font-size: 16px;
    color: #fff;
    text-align: center;
}

// shopify--save-v1
.shopify-save-v1 {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 130px;
    border-radius: 8px 0;
    padding: 7px 26px;
    background: linear-gradient(261deg,#ec8e1b 17.46%,#fba11a 64.01%,#fed03c 127.35%);
    font-size: 24px;
    line-height: 1.1;
    font-family: var(--ff-woff2-M);
    color: #fff;
    text-align: center;
}

// shopify--save-v2
.shopify-save-v2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 64px;
    background: url('https://ecoflow-service-us-prod.oss-us-west-1.aliyuncs.com/configurable/images/1708487234859/bb1.png');
    background-repeat: no-repeat;
    background-position: left top;
    line-height: 1.1;
    font-family: var(--ff-woff2-M);
    color: #fff;
    text-align: center;
    .shopify-save-line-a {
        display: block;
        padding-top: 8px;
        font-size: 20px;
    }
    .shopify-save-line-b {
        display: block;
        font-size: 14px;
    }
}

.shopify-order-top {
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 76px;
    .shopify-order-top-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-top: 26px;
        font-family: var(--ff-woff2-M);
    }
    .shopify-order-top-text-a {
        font-size: 12px;
        line-height: 12px;
    }
    .shopify-order-top-text-b {
        font-size: 16px;
        line-height: 16px;
    }
    
}

.shopify-get-points {
    position: absolute;
    top: 24px;
    left: 90px;
    display: flex;
    align-items: flex-end;
    height: 20px;
    padding-left: 90px;
    background-color: #FFE06B;
    border-radius: 20px;
    .shopify-get-points-icon1 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 28px;
        height: 32px;
    }
    .shopify-get-points-text-a {
        position: absolute;
        left: 30px;
        bottom: 0;
        color: #F1951B;
        text-align: center;
        text-shadow: 0.704px 0.704px 0px #C4910C, 0.352px 0.352px 0px #C4910C, 1.409px 1.409px 0px #C4910C, 1.057px 1.057px 0px #C4910C;
        -webkit-text-stroke-width: 0.876611590385437;
        -webkit-text-stroke-color: #FFE39C;
        font-family: var(--ff-woff2-B);;
        font-size: 33.812px;
        line-height: 1;
        letter-spacing: 0.338px;
        text-transform: capitalize;
    }
    .shopify-get-points-text-b {
        font-family: var(--ff-woff2-M);
        color: #745629;
        font-size: 14px;
        background-color: #F6BC26;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 0 8px;
        height: 20px;
        line-height: 20px;
    }
}

.shopify-sell-process {
    position: relative;
    margin: 0 auto 24px;
    width: 256px;
    height: 17px;
    border-radius: 13px;
    background: #FCEBD1;
    border: 1px solid #F29B19;
    display: flex;
    .shopify-sell-process-in {
        position: relative;
        display: block;
        height: 17px;
    }
    .shopify-sell-process-text {
        height: 17px;
        line-height: 17px;
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        padding: 0 8px;
        border-radius: 13px;
        color: #745629;
        text-align: left;
        margin-left: -1px;
        margin-top: -1px;
        background-image: url('https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709191596632/1x.png');
    }
    .shopify-sell-process-icon {
        position: absolute;
        top: -4px;
        right: -1px;
        width: 25px;
        height: 25px;
        .shopify-sell-process-icon-b2 {
            position: absolute;
            top: 28px;
            left: 0;

            display: block;
        }
    }
    .shopify-sell-process-text-b {
        height: 17px;
        line-height: 17px;
        font-family: var(--ff-woff2-M);
        font-size: 12px;
        padding: 0 8px;
        border-radius: 13px;
        color: #745629;
        text-align: center;
        white-space: nowrap;
        display: flex;
    }
}

// shopify 购物车
.editor-shopify-card-right-mod {
    position: fixed;
    bottom: 252px;
    right: 20px;
    z-index: 996;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: hsla(0,0%,100%,.96);
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.1);
    border-radius: 50%;
    .editor-shopify-card-right-num {
        background-color: #f8200c;
        width: 22px;
        height: 22px;
        text-align: center;
        padding: 2px 0;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: -6px;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        display: flex;
        justify-content: center;
        font-family: var(--ff-woff2-B);
        font-size: 12px;
        line-height: 18px;
        color: #fff;
    }
}

.editor-shopify-card-win {
    position: fixed;
    top: 0;
    right: 0;
    // z-index: 9999;
    z-index: 1000000; // 因为LiveChatWidget的层级设在了6个9..
    width: 340px;
    height: 100vh;
    background-color: #fff;
    .editor-shopify-card-win-mainer {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }
    .editor-shopify-card-win-mainer::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        overflow: visible;
        background-color: transparent;
        box-shadow: inset 1px 1px 0 transparent;
    }
    .editor-shopify-card-win-mainer::-webkit-scrollbar-track {
        background: transparent;
    }
    .editor-shopify-card-win-mainer::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #626262;
        background-clip: padding-box;
    }
    .editor-shopify-card-win-close {
        position: absolute;
        top: 18px;
        right: 26px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .editor-shopify-card-win-header {
        padding: 18px 0 12px;
        margin: 0 25.5px;
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #ededed;
    }
    .editor-shopify-card-win-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 20px;
        box-shadow: 0 -5px 8px 0 hsla(0,0%,92%,.25);
        text-align: center;
        background-color: #fff;
        .editor-shopify-card-win-header-price {
            display: flex;
            justify-content: space-between;
            align-content: center;
            font-family: var(--ff-woff2-B);
            font-size: 14px;
            line-height: 20px;
        }
        .editor-shopify-card-win-header-checkout {
            margin-top: 15px;
            display: inline-block;
            padding: 10px 24px;
            background-color: #2164ff;
            color: #fff;
            border-radius: 42px;
            width: 100%;
            font-family: var(--ff-woff2-B);
            font-size: 17px;
            line-height: 23px;
            cursor: pointer;
        }
    }
}

.editor-shopify-card-win-item {
    padding: 0 25.5px;
    .editor-shopify-card-win-item-good {
        display: flex;
        align-items: center;
        .editor-shopify-card-win-item-l {
            width: 65px;
            height: 65px;
        }
        .editor-shopify-card-win-item-r {
            margin-left: 8px;
            padding-right: 40px;
            font-size: 13px;
            line-height: 17.5px;
            letter-spacing: 0;
        }
    } 
    .editor-shopify-card-win-item-bottom {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .shopify-opera-mod {
            margin-left: auto;
        }
        .editor-shopify-card-win-item-r {
            margin-left: 8px;
        }
    } 
}

// 添加|减少 样式
.shopify-opera-mod {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 28px;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    .shopify-opera-mod-btn {
        width: 25px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        background-color: #f8f8f8;
        cursor: pointer;
        user-select: none;
    }
    .shopify-opera-mod-number {
        width: 48px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 5px;
        font-family: var(--ff-woff2-M);
        outline: none;
        border: none;
    }
}

// 锚点滚动往下100px
.editor-temp {
    scroll-margin: 100px;
}